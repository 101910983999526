.main-header-v2 {
  height: 4.0625rem;
  width: 100%;
  background: #fff;
  color: #000;
  border-bottom: 1px solid #eff2f6;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;
  padding: 0 5rem;
}

.header-container {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 100%;
  max-width: 62.5rem;
  margin: 0 auto;
}

.header-container .logo {
  margin-right: auto;
  cursor: pointer;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.header-container .nav-items {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.header-container .nav-items .nav-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 3.125rem;
  margin: 0 0.3125rem;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  width: 5rem;
}

.header-container .nav-items .nav-item .nav-item-header {
  font-size: 1.5625rem;
  color: #000;
  position: relative;
}

.header-container .svg_icon {
  width: 1.9rem;
  margin-top: 15px;
  -webkit-transition: width 50ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: width 50ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

.header-container .svg_icon:active {
  width: 1.7rem;
  margin-bottom: 3px;
}

.header-container .nav-items .nav-item .nav-item-text {
  font-size: 0.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-weight: 500;
  color: black;
  margin-bottom: 1rem;
  margin-top: 0.2rem;
}

.nav-items .active .nav-item-text {
  font-weight: 800;
  text-shadow: none;
}

.active .svg_icon {
  filter: invert(22%) sepia(42%) saturate(979%) hue-rotate(210deg)
    brightness(98%) contrast(95%);
}

.nav-items .icon_active {
  display: none;
}

.nav-items .icon_inactive {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.nav-items .active .icon_inactive {
  display: none;
}

.nav-items .active .icon_active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.nav-profile {
  margin-left: 1rem;
}

.nav-profile .dropdown {
  display: inline-block;
}

.nav-profile .profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.625rem 0.9375rem;
  border: 1px solid #d6dae0;
  border-radius: 5px;
  max-height: 3.125rem;
  cursor: pointer;
}

.nav-profile .profile .avatar {
  border-radius: 50%;
  display: block;
  position: relative;
  width: 2.5rem;
  background-size: 100%;
}

.nav-profile .profile .avatar-img {
  width: 100%;
  height: 100%;
  background: center no-repeat;
  background-size: cover;
  border-radius: 50%;
  display: block;
  /* padding-top: 100%; */
  position: relative;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.nav-profile .text {
  padding-right: 0.625rem;
  line-height: 0;
  height: 35px;
}

.nav-profile .name {
  font-size: 0.875rem;
  color: #29253d;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 22px;
  white-space: nowrap;
  max-width: 100px;
  padding-top: 8px;
  margin-bottom: 9px;
}

.nav-profile .name-sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.75rem;
  color: #788699;
  margin-top: -4px;
}

.mob-menu-overlay {
  position: fixed;
  z-index: 900;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(16, 16, 16, 0.7);
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: opacity 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

.navbar-toggler-icon {
  display: none;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: '';
  background: no-repeat center center;
  background-size: 100% 100%;
  z-index: 999;
  font-size: 22px;
  cursor: pointer;
}

.menu-toggler-open {
  display: none;
}

.profile-menu {
  padding: 0.28125rem 0;
  min-width: 12.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-height: 70vh;
  overflow-y: auto;
  max-width: 65px;
}

.profile-menu-item {
  padding: 0.15625rem 0.875rem;
  cursor: pointer;
  -webkit-transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 13px;
}

.profile-menu-item:hover {
  background: #eff2f6;
}

.profile-menu hr {
  margin-top: 5px;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 63.9375em) {
  .header-container .nav-items .nav-item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1.125rem;
    margin: 0 1.25rem 0.75rem;
    width: auto;
    width: initial;
  }
  .header-container .svg_icon {
    width: 2rem;
  }
  .nav-profile .profile .avatar {
    width: 2.5rem;
  }
  .header-container .nav-items {
    position: fixed;
    top: 0;
    right: -100px;
    width: 0;
    -webkit-transition: width 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
    transition: width 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
    height: 100%;
    background: #fff;
    z-index: 999;
    overflow-x: hidden;
    -webkit-box-pack: initial;
    -ms-flex-pack: initial;
    justify-content: initial;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 20px;
    box-shadow: -20px -1px 20px 20px #00000005;
  }
  .header-container .nav-items .nav-item .nav-item-text {
    font-size: 1rem;
    display: flex;
    flex-shrink: 0;
    font-weight: 500;
    color: black;
    /* margin-bottom: 1rem; */
    margin-top: 1.85rem;
    display: inline;
    padding-left: 1rem;
  }
  .header-container .profile {
    margin-top: 20px;
    width: 230px;
  }
  .mobile-expand {
    width: 400px !important;
  }
  .navbar-toggler-icon,
  .menu-toggler-open {
    display: inline-block;
  }
  .nav-profile .text {
    line-height: 0;
    height: 35px;
    padding-left: 5px;
    margin-right: -15%;
    min-width: 90%;
  }
  .profile-menu {
    min-width: 14.4rem;
    top: 5px !important;
  }
  .profile-menu-item {
    font-size: 15px;
    padding: 0.25625rem 0.875rem;
  }

  .bg-when-mobile-menu {
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    background: #1e1e1e;
    opacity: 0.5;
    transition: background ease 0.2s;
  }
  .nav-profile .name {
    max-width: 80%;
  }
}

@media only screen and (max-width: 576px) {
  .main-header-v2 {
    padding: 0 1rem;
  }
}

.filter-bar {
  display: flex;
  background: #fff;
  border-bottom: 1px solid #d6dae0;
  min-height: 3.125rem;
  position: -webkit-sticky;
  position: sticky;
  z-index: 12;
  top: 0.65rem;
  margin-bottom: 0;
}

.filter-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  max-width: 64.5rem;
  margin: 0 auto;
  padding-left: 9px;
  padding-right: 9px;
}

@media only screen and (max-width: 31.9375em) {
  .filter-container {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow: auto;
    white-space: nowrap;
  }
}

.filter-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 2.1875rem;
  border: 1px solid #d6dae0;
  border-radius: 4px;
  margin: 0.25rem 0.5rem 0.25rem 6px;
}

.filter-title {
  width: 100%;
  display: inline-block;
}

.filter-item .title-container {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding-bottom: 0.3125rem;
  padding-top: 0.3125rem;
}

.filter-item .title-item {
  font-size: 1.1rem;
  width: auto;
  width: initial;
  margin-right: 0.4375rem;
  position: static;
  position: initial;
  margin-left: 0.4375rem;
}

.checkbox__label .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  -webkit-transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  border: 1px solid #b6b9be;
}

.checkbox-list .checkbox-list {
  margin-left: 2.125rem;
}

.checkbox__label {
  display: block;
  position: relative;
  padding-left: 2.125rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox__label:hover input ~ .checkmark {
  background-color: #d6dae0;
}

.checkbox__label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox__label input:checked ~ .checkmark {
  background-color: #433881;
  border-color: #433881;
}

.checkbox__label input:checked ~ .checkmark:after {
  display: block;
}

.checkbox__label input:checked ~ .checkmark.checkmark--azul {
  background-color: #01a3a4;
  border-color: #01a3a4;
}

.checkbox__label .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  -webkit-transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  border: 1px solid #b6b9be;
}

.checkbox__label .checkmark.radio {
  border-radius: 50%;
}

.checkbox__label .checkmark:not(.radio):after {
  content: '';
  position: absolute;
  display: none;
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.filter-item .bs-popover-auto[x-placement^='bottom'],
.bs-popover-bottom {
  margin-top: 14px;
}

.filter-item .popover-dropdown {
  min-width: 220px;
}

.filter-list {
  max-height: 162px;
  overflow-y: auto;
  min-width: 260px;
}

.filter-item .popover-body {
  padding: 1rem 0.75rem;
  color: #494e54;
}

.filter-dropdown-container {
}

.filter-dropdown-container .dropdown-footer {
  padding-top: 0.375rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 0.875rem;
  font-weight: 500;
}

.dropdown__button {
  font-weight: bold;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9;
  cursor: pointer;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.filter-dropdown__button--apply,
.filter-dropdown__button--destructive {
  color: #e0133e;
  background: #fff3fc;
  border: none;
  padding: 7px;
  border-radius: 6px;
  font-weight: 400;
  cursor: pointer;
}

.filter-dropdown__button--apply {
  color: #433881;
  background: #f8f8f8;
  width: 80px;
}

.filter-title-text {
  padding-right: 7px;
}

.sort-dropdown {
  display: inline;
  width: 155px;
  height: 35.5px;
  margin-top: px;
  font-size: 1.1rem;
  width: auto;
  width: initial;
  margin-right: 0.4375rem;
  position: static;
  position: initial;
  margin-left: 0.4375rem;
  padding-top: 1px;
  padding-left: 4px;
  color: #313131;
  margin-left: auto;
}

.google-login {
  text-align: center;
}

.google-login a {
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
}
.google-login .button {
  display: inline-block;
  max-width: 300px;
  margin-top: 15px;
  border: 0;
  padding: 0 18px;
  text-align: left;
  width: 100%;
  height: 37px;
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: 'liga' on;
  color: rgba(0, 0, 0, 0.84) !important;
  fill: rgba(0, 0, 0, 0.84) !important;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.18);
  font: inherit;
  outline: none;
  text-align: center;
}
.google-login .button .svgIcon {
  vertical-align: middle;
  fill: rgba(0, 0, 0, 0.54);
  padding-right: 4px;
  height: 37px;
  display: inline-block;
}

.login-sep {
  text-align: center;
  margin-top: 15px;
  text-align: center;
  margin-top: 15px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
}

.svg-facebook {
  margin-top: 5px;
  margin-left: 5px;
  padding-left: 3px;
  padding-right: 4px;
  fill: #3b5998;
}

.new-notifs-header {
  background: red;
  content: '';
  padding: 5px;
  position: absolute;
  border-radius: 50%;
  margin-top: -28px;
  margin-left: 22px;
}

.new-notifs-mobile {
  margin-top: 2px;
  margin-left: -19px;
}

@media only screen and (min-width: 63.9375em) {
  .new-notifs-mobile {
    display: none;
  }
}
