.explore-container {
  max-width: 64.5rem;
  margin-top: 30px;
}

.btn-primary {
  color: #fff;
  background-color: #493e85;
  border-color: #493e85;
}

.btn-primary:hover {
  background-color: #3a306d;
  border-color: #3a306d;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #443b74;
  border-color: #443b74;
}

.task-search-box {
  font-size: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  height: 3.125rem;
}

.dt-description {
  font-size: 13px;
  border: #efefef solid 1px;
  border-radius: 3px;
  background: #fcfcff;
}

.dt-modal .dt-loading {
  min-height: 300px;
}

.search-container {
  background: #fff;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 0.3125rem;
  margin-bottom: 0.625rem;
  position: relative;
  width: 100%;
}

.search-container:before {
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0.3125rem;
  content: '';
}

.search-container:before {
  z-index: 8;
  content: '';
  background-color: #033443;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABmJLR0QA/wD/AP+gvaeTAAABB0lEQVQ4jdXTv0rDUBQH4C8q6iZCB3V06tQu7oI+gJuCezeHdlUKfRBdBQVnNx9ARIfOxToKDqIg+LcdbgpVae6tuPRAuIEcvpyE32FSq4JDdPGGe5xjG9k4UIYWPnGNfexiD2f4wAVKqWALr6iNmKSKDi4xH8Mq+WS1SN8qHtGMgUfCZ6b8oyYeMFPU1MVBAgZl9LD288HU0P0S7hLB2/xcKQKfsZAILubnUxF4hY1EcFOI0E1R007eVI1gc2jjNPbWTAhtR4jGKOwYLwU936okhHaQszJmsSxsTDvHvtBIAQkbMMhZb+h6x0k+WWNcFKaFnG1h3e8E/AmN1QCt/ydaF7ZsQqoPxhA4y5kPLhAAAAAASUVORK5CYII=')
    1rem 50% no-repeat;
  width: 3.125rem;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.search-container:after {
  border: 1px solid #d6dae0;
  opacity: 1;
}

.task-list-section {
  padding: 0.9375rem;
  margin-bottom: 0.9375rem;
  overflow: hidden;
  border-radius: 0.3125rem;
  border: 1px solid #d6dae0;
  background: #fff;
  -webkit-transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

.task-list-title {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 0.9375rem;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  text-transform: uppercase;
  color: #788699;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.task-list-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto;
  -ms-grid-columns: 1fr;
  grid-template: auto/1fr [end];
  grid-gap: 0.9375rem;
  justify-items: stretch;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

@media only screen and (min-width: 32em) {
  .task-list-container {
    -ms-grid-rows: auto;
    -ms-grid-columns: 1fr 0.9375rem 1fr;
    grid-template: auto/repeat(2, 1fr) [end];
  }
}

@media only screen and (min-width: 48em) {
  .task-list-container {
    -ms-grid-rows: auto;
    -ms-grid-columns: 1fr 0.9375rem 1fr 0.9375rem 1fr;
    grid-template: auto/repeat(3, 1fr) [end];
  }
}

.feed-card {
  width: 100%;
  margin: 0;
  padding: 0.9375rem;
  margin-bottom: 0.9375rem;
  overflow: hidden;
  border-radius: 0.3125rem;
  border: 1px solid #d6dae0;
  background: #fff;
  -webkit-transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.feed-card.card-skeleton {
  border: 1px solid #f5f5f5;
}

.how-it-works-card {
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative;
  min-height: 16.0625rem;
  background: #d00865;
  background: center no-repeat;
  background-size: cover;
  background-image: url(/inc/feed-card-bg.png);
}

.feed-card--how-it-works-header {
  color: #fff;
  width: 80%;
  margin-bottom: 1.3rem;
  font-size: 1.1rem;
  line-height: 1.6875rem;
  font-weight: 500;
}

.find-out-button {
  color: rgb(255, 255, 255);
  border: none;
  -webkit-transition: color 125ms;
  -o-transition: color 125ms;
  transition: color 125ms;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.375rem 0.75rem;
  border-radius: 0.3125rem;
  font-weight: 600;
  font-size: 0.875rem;
  -webkit-transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  border: white solid 1px;
}

.feed-card--how-it-works-body {
  color: #fff;
  margin-bottom: 25px;
  font-size: 1rem;
}

.feed-card--header {
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 600;
}

.up-headline {
  font-size: 12px;
  font-weight: 500;
  color: #898790;
  margin-bottom: 0px;
  margin-top: 20px;
}

.feed-card--applicants {
  margin-left: auto;
  font-size: 11px;
  font-weight: 500;
  color: #a09cb5;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  float: right;
  margin-top: -16px;
}

.feed-card--container {
  -webkit-box-flex: 1;
  -ms-flex: 1 0;
  flex: 1 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 180px;
}

.feed-card--sep {
  background: #d6dae0;
  width: auto;
  width: initial;
  margin: 0.9375rem calc(-0.9375rem);
}

.feed-card-person-details {
  margin-top: 10px;
}
.feed-card-person-details,
.feed-card-person-details .from {
  font-size: 11px;
}

.feed-card-person-details .loc {
  font-size: 11px;
  color: #585858;
}
.feed-card--sep2 {
  width: 10px;
}
.skill-section {
  margin-top: 14px;
}
.skill-section .profile-skills {
  margin-right: 6px;
  margin-left: 0px;
}
.feed-card--category {
  font-size: 10px;
  background: #433881;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 6px;
  padding-right: 6px;
  float: left;
  color: white;
  border-radius: 2px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.card-skeleton .feed-card--category {
  background: #eeeeee;
  width: 69px;
}

.card-skeleton .feed-card-person-details,
.feed-card-person-details .from {
  width: 83px;
}

.feed-card--points {
  background: #433881;
  color: white;
  width: 41px;
  font-weight: 500;
  padding: 2px 0px 4px 0px;
  text-align: center;
  border-radius: 7px;
  margin-left: auto;
  margin-top: auto;
}

.card-skeleton .feed-card--points {
  background: #f1f1f1;
  height: 24px;
  width: 30px;
}

.feed-card--points .pts {
  display: block;
  font-size: 6px;
}
.fc-pts {
  font-size: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: 7px;
  margin-bottom: -45px;
}

.feed-card--footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.feed-card--footer-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-right: 0.75rem;
  align-items: center;
}

.feed-card--footer-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.feed-card-learn-more {
  background: #fff;
  color: #433881;
  border: 1px solid #433881;
  -webkit-transition: color 0s;
  -o-transition: color 0s;
  transition: color 0s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 5px 7px;
  border-radius: 0.3125rem;
  font-weight: 600;
  font-size: 12px;
  -webkit-transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.card-skeleton .feed-card-learn-more {
  cursor: inherit;
  border: none;
  width: 70px;
  background: #efefef;
}

.card-skeleton .feed-card-learn-more:hover {
  background: none;
}

.feed-card-learn-more:hover {
  color: #fff;
  background: #433881;
}

.feed-card--footer-left .svg_icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.feed-card:hover {
  -webkit-box-shadow: 0 0 11px #ddd9d9;
  box-shadow: 0 0 11px #ddd9d9;
  cursor: pointer;
}

.card-skeleton:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: inherit;
}

.dt-header .dt-sub-title {
  color: #727272;
  font-size: 11px;
  margin-bottom: -2px;
}

.dt-skills {
  margin-left: 0px !important;
  margin-right: 7px;
}

.dt-title {
  margin-bottom: 3px;
}

.dt-modal {
  max-width: 80%;
  -webkit-transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

.pro-modal {
  max-width: 60%;
  margin-top: 22vh;
}

@media (max-width: 576px) {
  .dt-modal {
    max-width: 100%;
  }
  .pro-modal {
    max-width: 100%;
  }
}

.dt-added-on {
  font-size: 9px;
  color: #788699;
  font-weight: 400;
}

.dt-body {
  /* padding-top: 0; */
  overflow-y: scroll;
  max-height: 68vh;
  min-height: 35vh;
  -webkit-transition: min-height 0.7s linear;
  -o-transition: min-height 0.7s linear;
  transition: min-height 0.7s linear;
  font-size: 14px;
}

.dt-body.loading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 74vh;
}

.dt-action-box {
  border-bottom: 1px solid #e9ecef;
  padding: 9px 15px;
}

.dt-points {
  font-weight: 500;
  color: #493e85;
}

.dt-action-btn {
  font-size: 14px;
  padding: 3px 8px;
}

.description-editor {
  padding: 0px;
  border: none;
}

.task-list-dashboard {
  -ms-grid-rows: auto;
  -ms-grid-columns: 1fr;
  grid-template: auto/1fr [end];
}

@media only screen and (min-width: 32em) {
  .task-list-dashboard {
    -ms-grid-rows: auto;
    -ms-grid-columns: (1fr) [1];
    grid-template: auto/repeat(1, 1fr) [end];
  }
}

@media only screen and (min-width: 48em) {
  .task-list-dashboard {
    -ms-grid-rows: auto;
    -ms-grid-columns: (1fr) [2];
    grid-template: auto/repeat(2, 1fr) [end];
  }
}

.pro-textarea {
  margin-top: 9px;
  resize: none;
}

.profile-skills {
  cursor: pointer;
  -webkit-transition: ease-in-out;
  -o-transition: ease-in-out;
  transition: ease-in-out;
}

.profile-skills:hover {
  background-color: #3a306d;
  color: white;
}

.mytasks-container {
  max-width: 64.5rem;
  margin-top: 30px;
}

.mytasks-section {
  padding: 0.9375rem;
  margin-bottom: 0.9375rem;
  overflow: hidden;
  border-radius: 0.3125rem;
  border: 1px solid #d6dae0;
  background: #fff;
  -webkit-transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

.mytasks-tabs {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.mytasks-tabs .active {
  text-shadow: none;
  font-weight: 500;
  color: #493e85 !important;
  border-top: #493e85 solid 2px;
}

.mytasks-table {
  margin-top: 27px;
  width: 105%;
  max-width: 105%;
  margin-bottom: 1rem;
  margin-left: -15px;
  border-top: #433881 solid 3px;
  background-color: transparent;
}

@media only screen and (max-width: 32em) {
  .mytasks-date,
  .mytasks-date-h {
    display: none;
  }
  .mytasks-table,
  .mytasks-tabs,
  .mytasks-note {
    font-size: 12px;
  }
}

.taskv-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 82vh;
}

.dv-title {
  font-weight: 500;
}

.dt-title-anchor {
  text-decoration: none;
  color: inherit;
}
.dt-title-anchor:hover {
  color: inherit;
}

.pro-list-container {
  border: #e0dbdb solid 1px;
  padding: 9px 8px;
  border-radius: 4px 4px 0px 0px;
  background: #fefeff;
}

.pro-list-user {
  font-size: 11px;
  margin-top: 9px;
  color: #5a5a5a;
}

.pro-list-user .user-name {
  color: #433881;
  font-weight: 500;
}

.pro-list-container .posted-on {
  text-align: right;
  font-size: 11px;
  color: #8e8e8e;
  margin-top: 9px;
}

.pro-list-action {
  margin-bottom: 16px;
  text-align: right;
  padding: 7px 5px;
  border: #dedede solid 1px;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
  background: #f9f9f9;
}

.pro-list-action .act-btn {
  font-size: 10px;
  margin-left: 6px;
  padding: 4px 10px;
}

.pro-list-action .act-btn._accept,
.pro-list-action .act-btn._accepted:disabled {
  background-color: #50c76d;
  color: white;
  opacity: 1;
}

.pro-list-action .act-btn.hide {
  display: none;
}

.pro-list-action .act-btn._reject,
.pro-list-action .act-btn._rejected:disabled {
  background-color: #c75050;
  color: white;
  opacity: 1;
}

.pro-list-action .act-btn._negotiate {
  background-color: #507dc7;
  color: white;
}

.low-opacity {
  opacity: 0.5;
}

.filter-btn {
  margin-bottom: 14px;
  font-size: 11px;
  padding: 5px 8px;
}

.description-editor .ql-editor {
  min-height: 80px !important;
}

.description-editor .ql-toolbar.ql-snow {
  background: whitesmoke !important;
}

.tu-btn {
  float: right;
  margin-top: 10px;
  font-size: 15px;
  padding: 5px 20px;
}

.tu-info {
  float: left;
  font-size: 10px;
  max-width: 40vw;
  margin-top: 8px;
  margin-left: 2px;
}

.tu-heading {
  color: #433881;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
  border-top: #dadada solid 1px;
  padding-top: 14px;
}

.tu-update-top {
  padding: 5px 10px;
  background: #f0edff;
  font-size: 11px;
}
.tu-owner-pill {
  float: right;
  border: #433880 solid 0.5px;
  border-radius: 4px;
  padding: 0px 3px;
  font-size: 10px;
  font-weight: 500;
  background: #f9f8ff;
}

.tu-update-name {
  font-weight: 600;
}

.sm-task-top {
  font-size: 10px;
}

.sm-task-footer {
  border-top: #efefef solid 1px;
  padding-top: 4px;
  margin-top: 10px;
}

.task-state {
  font-size: 9px;
  background: #424242;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 9px;
  border-radius: 10px;
  color: white;
  font-weight: 500;
  float: right;
}

.task-state.--assigned {
  background: #009688;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #433881;
  border-color: #433881;
}

.task-card-text {
  font-size: 13px;
}

.tu-profile .profile-badge-dp > img {
  width: 64px;
}

.tu-profile .profile-badge-name {
  font-size: 14px;
}

.sticky-columns {
  position: fixed;
  width: 30%;
  right: 6%;
}

@media only screen and (max-width: 48em) {
  .sticky-columns {
    position: inherit;
    width: 100%;
    right: inherit;
  }
}

@media only screen and (min-width: 90em) {
  .sticky-columns {
    position: fixed;
    max-width: 400px;
    right: inherit;
    left: calc(50vw + 171px);
  }
}

.tu-icon {
  font-size: 25px;
}

.tu-border-highlight {
  border: #9388cd solid 1px;
}

.tu-accept-box {
  border: #02c836 solid 1px;
}

.tu-accept-box .tu-accept-text,
.tu-accept-box .tu-icon {
  color: #1ca440;
  font-weight: 500;
}

.tu-accept-box .tu-update-top {
  background: #edfff0;
}

.act-rej {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.reject-btn,
.accept-btn {
  max-width: 45%;
}

.accept-btn {
  background: #0ac53b;
}
.reject-btn {
  background: #c50a0a;
}
.points-change-btns {
  display: inline;
}

.points-change-btns .btn {
  font-size: 9px;
  margin-right: 9px;
}

.end-explore {
  text-align: center;
  color: #433881;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 1.125rem;
  margin-top: 0.9375rem;
  border-top: #dfdbf5 solid 1px;
  padding-top: 11px;
}

.filter-info {
  font-size: 14px;
  font-weight: 500;
}

.feedback-top {
  text-align: center;
}

.feedback-top .done-icon {
  width: 90px;
}

.feedback-top .cong-text {
  font-size: 12px;
  font-weight: 500;
}

.feedback-mid {
  font-size: 12px;
  font-weight: 500;
  border-top: #00000014 solid 1px;
  padding-top: 20px;
  margin-top: 20px;
}

.feedback-bottom {
  font-size: 12px;
  font-weight: 500;
  margin-top: 25px;
}

.feedback-bottom .feedback-box {
  font-size: 12px;
  font-weight: 500;
  margin-top: 7px;
}

.feedback-action {
  text-align: right;
  margin-top: 12px;
}

.feedback-action .btn-feedback {
  font-size: 12px;
  width: 82px;
}

.feedback-action .btn-feedback-later {
  background: none !important;
  color: #443b74;
}

.rating {
  direction: rtl;
  unicode-bidi: bidi-override;
  color: #ddd; /* Personal choice */
}
.rating input {
  display: none;
}
.rating label:hover,
.rating label:hover ~ label,
.rating input:checked + label,
.rating input:checked + label ~ label {
  color: #ffc107; /* Personal color choice. Lifted from Bootstrap 4 */
}

.feedback-rating .rate-icon {
  width: 23px;
}

.feedback-mid .rate-work {
  margin-top: 4px;
}

.feedback-rating {
  text-align: right;
}

.feedback-text {
  font-style: italic;
  font-weight: 500;
  text-align: center;
  margin-top: 14px;
}

.feedback-block .feedback-update {
  margin-top: 10px;
  text-align: center;
}

.tu-feedback {
  background: #fff9ed;
}

.vector-404 {
  min-width: 200px;
  max-width: 30vw;
  margin: 50px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.share-popup-body {
  text-align: center;
  display: block;
  overflow: hidden;
  padding-top: 35px;
  min-height: 27vh;
}
