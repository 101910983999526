.darktheme,
.darktheme .description-editor .ql-toolbar.ql-snow,
.darktheme .msg-container,
.darktheme .ReactModal__Content,
.darktheme .msg-right .msg-proposal-title {
  background: #27292d !important;
  color: white;
}

.darktheme a,
.darktheme .pro-list-user .user-name {
  color: #6fb4ff;
}

.darktheme .card,
.darktheme .feed-card--sep,
.darktheme .tu-owner-pill,
.darktheme .profile-status-select,
.darktheme .task-search-box,
.darktheme .chat-typing-container .chat-editor,
.darktheme .pro-list-container {
  background: #383b40 !important;
  color: #d1d1d2;
}

.darktheme .notification-item,
.darktheme .feed-card,
.darktheme .modal-content,
.darktheme .convo-card:hover {
  box-shadow: 0 0 10px 0px #27292d3d;
  background: #26282b;
  border: #505359 solid 1px;
}

.darktheme .notification-item.unread-type {
  background: #1e2022;
}
.darktheme .unread-pill {
  background: #bdb1ff;
}

.darktheme .pro-list-action {
  background: #26282b;
}

.darktheme .notif-list {
  border-top: #505359 solid 1px;
}

.darktheme .notification-item .notif-text {
  color: #bcbcbd;
}

.darktheme .sm-task-footer {
  border-top: #444444 solid 1px;
}

.darktheme .main-header-v2,
.darktheme .notif-section,
.darktheme .filter-bar,
.darktheme .task-list-section,
.darktheme .mytasks-section {
  background: #383b40;
  color: #d3d3d3;
}

.darktheme .nav-items .icon_inactive,
.darktheme .feed-card--footer-left .svg_icon,
.darktheme .nav-items .active .icon_active,
.darktheme .search-container:before {
  filter: brightness(0) invert(1);
}

.darktheme .header-container .nav-items .nav-item .nav-item-text,
.darktheme .add-task-input-headline .max-chars,
.darktheme .add-task-input-details .max-chars,
.darktheme .add-task-duration .max-chars,
.darktheme .add-task-categories .max-chars {
  color: #bcbcbd;
}

.darktheme .nav-profile .name,
.darktheme .nav-profile .name-sub,
.darktheme .profile-badge-name,
.darktheme .profile-badge-rating,
.darktheme .profile-badge-stats,
.darktheme .profile-badge-category,
.darktheme .profile-badge-edit,
.darktheme .profile-skills,
.darktheme .footer .footer-links > a,
.darktheme .dt-title,
.darktheme .dt-body,
.darktheme .modal-content,
.darktheme .bio-text,
.darktheme .experience-date,
.darktheme .experience-desc,
.darktheme .profile-tagline,
.darktheme .profile-member-since,
.darktheme .ql-snow .ql-picker.ql-expanded .ql-picker-label,
.darktheme .ql-snow .ql-picker,
.darktheme .msg-left .message-bubble,
.darktheme .profile-menu-item,
.darktheme .pro-list-container {
  color: #d3d3d3;
}

.darktheme .notification-btn,
.darktheme .redeem-btn,
.darktheme .feed-card--category,
.darktheme .feed-card--points,
.darktheme .profile-skills:hover,
.darktheme .feed-card-learn-more:hover,
.darktheme .msg-right .message-bubble,
.darktheme .btn-primary,
.darktheme .add-task-btn,
.darktheme .confirm-button,
.darktheme .chat-typing-container .chat-send-btn,
.darktheme .tick-mark-completion,
.darktheme .msg-proposal-title {
  background: #6558b1;
}

.darktheme .redeem-heading,
.darktheme .profile-badge-name,
.darktheme .dt-points,
.darktheme .add-task-heading,
.darktheme .add-task-points-heading,
.darktheme .convo-card .personal-info .name,
.darktheme .tu-heading,
.darktheme .close {
  color: #ffffff;
}

.darktheme .profile-badge-category {
  background: #d8d8d81f;
}

.darktheme .feed-card {
  border: 1px solid #424242;
}

.darktheme .how-it-works-card {
  background: #6558b1;
  background: center no-repeat;
  background-size: cover;
  background-image: url(/inc/feed-card-bg.png);
}

.darktheme .feed-card:hover {
  box-shadow: 0 0 11px #0000003d;
  cursor: pointer;
}

.darktheme .profile-skills {
  background: #373158;
}

.darktheme .feed-card-person-details .loc,
.darktheme .convo-card .personal-info .lastmsg,
.darktheme .msg-time,
.darktheme .pro-list-user {
  color: #bdbcbc;
}

.darktheme .feed-card-learn-more {
  background: #414449;
  color: #c3c3c3;
  border: 1px solid #414449;
}

.darktheme .task-list-section,
.darktheme .form-control,
.darktheme .notif-section,
.darktheme .mytasks-section {
  border: 1px solid #4c4c4c;
}

.darktheme .task-list-title,
.darktheme .end-explore,
.darktheme .dt-header .dt-sub-title {
  color: #d4d5d8;
}

.darktheme .main-header-v2 {
  border-bottom: 1px solid #6d6d6d;
}

.darktheme .form-control,
.darktheme .msg-left .task-prop-task:hover,
.darktheme .custom-select {
  background-color: #292b2e;
  color: #d3d3d3;
}

.darktheme .dt-description {
  border: #454545 solid 1px;
  background: #333538;
}

.darktheme .dt-added-on,
.darktheme .notification-item .notif-time,
.darktheme .chat-typing-container .press-enter {
  color: #b9bec6;
}

.darktheme .conversations .is-selected {
  border-right: #d7d0ff solid 5px;
}

.search-container {
  background: none;
}

.darktheme .conversations .is-selected {
  box-shadow: 0 0 20px #00000036;
}

.darktheme .convo-card .personal-info,
.darktheme .date-icon-block {
  border-bottom: 1px solid #383838;
}

.darktheme .chat-messages-container {
  background: #383b40;
}

.darktheme .tu-update-top,
.darktheme .profile-menu-item:hover {
  background: #474747;
}

.darktheme .tu-border-highlight {
  border: #ffffff solid 1px;
}

.darktheme .msg-container .conversations {
  border-right: 1px solid #474747;
}
.darktheme .chat-header-container,
.darktheme .conversations .action-box,
.darktheme .chat-messages-container,
.darktheme .dt-action-box,
.darktheme .modal-header {
  border-bottom: 1px solid #474747;
}
.darktheme .modal-footer,
.darktheme .msg-left .task-prop-task {
  border-top: 1px solid #474747;
}

.darktheme .msg-left .task-prop-task {
  border-left: 1px solid #474747;
}

.darktheme .footer {
  border-top-color: #474747;
}

.darktheme .react-loading-skeleton {
  background-color: #373a3f;
  background-image: linear-gradient(
    90deg,
    #27292d,
    #27292d,
    #414449
  ) !important;
}

.darktheme .lds-ring.colored div {
  border: 3px solid #fff;
  border-color: #fff transparent transparent transparent;
}

@media only screen and (max-width: 63.9375em) {
  .darktheme .header-container .nav-items {
    background: #292b2e;
  }
}

.darktheme .profile-menu.show {
  background: #25272b;
  color: white;
}

.darktheme .ql-snow .ql-stroke {
  stroke: #d3d3d3;
}

.darktheme .ql-snow .ql-fill,
.darktheme .ql-snow .ql-stroke.ql-fill {
  fill: #d3d3d3;
}

.darktheme .checkbox__label input:checked ~ .checkmark,
.darktheme .chat-typing-container .chat-send-btn {
  background-color: #6558b1;
  border-color: #6558b1;
}

.darktheme .msg-left .message-bubble {
  background-color: #27292d;
}

.darktheme .mytasks-table {
  border-top: 3px solid #6558b1;
}

.darktheme .ReactModal__Overlay--after-open {
  background-color: rgba(37, 37, 37, 0.75) !important;
}

.darktheme .ql-editor.ql-blank::before {
  color: rgba(211, 207, 207, 0.6);
}

.darktheme .profile-menu hr {
  border-top: 1px solid rgb(81, 81, 81);
}

.darktheme .btn-feedback-later {
  color: white;
}
