.container-box {
  background-color: white;
}
.profile-tagline {
  margin-top: -10px;
  font-size: 17px;
  font-weight: 400;
  color: #575757;
}
.profile-member-since {
  margin-top: 2px;
  border-top: #e4e4e4 solid 1px;
  font-size: 13px;
  padding-top: 7px;
  margin-top: 13px;
  width: 189px;
  color: rgb(85, 83, 83);
}

.profile-name {
  font-size: 22px;
}

.bio-heading {
  font-weight: 600;
}

.bio-text {
  color: rgb(85, 83, 83);
}

.profile-status-select {
  max-width: 100%;
  margin-top: 5px;
  margin-bottom: 1px;
  background: #fcfcfc;
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
  padding: 6px;
  border: #dddddd solid 1px;
  min-width: 100%;
}

.profile-skills {
  width: fit-content;
  font-size: 12px;
  background: #4338811f;
  padding: 2px 5px 2px 5px;
  border-radius: 3px;
  display: inline;
  color: #504444;
  margin-left: 7px;
  white-space: nowrap;
  display: -webkit-inline-box;
  margin-bottom: 7px;
}

.confirm-button {
  color: white;
  background: #433881;
  min-width: 130px;
  font-size: 16px;
  border: none;
  height: 33px;
  border-radius: 5px;
  vertical-align: bottom;
  box-shadow: 1px 3px 4px #0000001c;
  cursor: pointer;
  padding: 0px 12px;
}

.cancel-button {
  color: #26316a;
  background: #ffffff;
  width: 130px;
  font-size: 16px;
  border: none;
  height: 33px;
  border-radius: 5px;
  vertical-align: bottom;
  /* box-shadow: 1px 3px #d5d5d5; */
  cursor: pointer;
  border: #b9b9b9 solid 1px;
}
.profile-username {
  font-size: 14px;
  margin-left: 10px;
}
.check-box {
}

.experience-company {
  font-size: 14px;
  font-weight: 600;
  padding-right: 8px;
}

.experience-title {
  font-size: 15px;
  font-weight: 400;
}

.experience-location {
  font-weight: 400;
  border-left: #c2b9f2 solid 1px;
  padding-left: 10px;
}

.experience-date {
  font-size: 13px;
  color: #4e4e4e;
}

.experience-desc {
  border-bottom: #e8e1e1 solid 1px;
  font-size: 13px;
  color: #4e4e4e;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 15px;
}

.experience-row {
  margin-bottom: 15px;
}

.profile-edit-col {
}
