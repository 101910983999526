.notif-container {
  max-width: 45.5rem;
  margin-top: 30px;
}

.notif-section {
  padding: 0.9375rem;
  margin-bottom: 0.9375rem;
  overflow: hidden;
  border-radius: 0.3125rem;
  border: 1px solid #d6dae0;
  background: #fff;
  -webkit-transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 125ms cubic-bezier(0.25, 0.1, 0.25, 1);
}

.notification-item {
  border: #e9e9e9 solid 1px;
  padding: 12px 8px;
  background: #fefeff;
  box-shadow: 0 0 10px 0px #fbfbfb;
  border-top: none;
  transition: all 0.1s ease-in-out;
  margin-bottom: 7px;
}

.notification-item.unread-type {
  background: #f4f4ff;
}

.unread-pill {
  background: #43387f;
  padding: 2px 5px;
  content: '';
  font-size: 5px;
  border-radius: 50%;
  position: relative;
  margin-left: 10px;
  vertical-align: middle;
  box-shadow: 0 0 5px #0000002b;
  opacity: 0.2;
}

.notification-item .notif-text {
  color: #433881;
  font-size: 13px;
}
.notification-item .notif-time {
  font-size: 10px;
  color: #636363;
}

.notif-section a {
  text-decoration: none;
}
.notif-list a {
  text-decoration: none;
}

.notification-item:hover {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 6px -1px #b2bac5;
  box-shadow: 0 1px 6px -1px #b2bac5;
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
}

.notif-list {
  border-top: #e9e9e9 solid 1px;
}

.Toastify__toast--info {
  background: #433881 !important;
}

.Toastify__toast-body {
  margin: auto 5px !important;
  font-size: 14px;
  font-weight: 400;
}
